import Vue from 'vue'
import Vuex from 'vuex'
import {
  ID_TOKEN_LOGIN,
  USER_INFO_LOGIN,
} from "./action-types";
import {
  SET_USER_PARAMETER,
} from "./mutation-types";

Vue.use(Vuex)

const BACKEND_API_URL = process.env.VUE_APP_BACKEND_API_URL
import axios from 'axios';
export default new Vuex.Store({
  state: {
    isLoading: false,
    user : {},
  },
  getters: {
  },
  mutations: {
    [SET_USER_PARAMETER](state, userParameter) {
      console.log('userParameter', userParameter)
      for(const key in userParameter){
        state.user[key] = userParameter[key]
      }
    },
  },
  modules: {
  },
  actions: {
    [ID_TOKEN_LOGIN]: async ({ commit },id_token) => {
      console.log('ID_TOKEN_LOGIN')
      await axios
        .post(BACKEND_API_URL + "line-id-token-login", {id_token: id_token})
        .then((res) => {
          console.log('れす',res)
          if(res?.data?.body?.user){
            commit(SET_USER_PARAMETER, {user_code : res?.data?.body?.user?.user_code, access_token : res?.data?.body?.user?.access_token});
          }
        })
        .catch((err) => console.log(err));
    },
    [USER_INFO_LOGIN]: async ({ commit, state },user_info) => {
      console.log('USER_INFO_LOGIN')
      await axios
        .post(BACKEND_API_URL + "user-info-login", {user: { username : user_info.username, password : user_info.password, id_token : state.user.id_token }})
        .then((res) => {
          console.log(res, commit)
          if(res?.data?.user_code){
            commit(SET_USER_PARAMETER, {user_code : res.data.user_code});
          }
        })
        .catch((err) => console.log(err));
    },
  }
})
