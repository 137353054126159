<template>
  <div class="appInner">
    <StandardHeader></StandardHeader>
    <MemberShipCardView class="mt-5 mb-5"></MemberShipCardView>
    
    <div style="display: flex;" class="mt-4 mr-4 mb-5">
    </div>
    
    <div class="mt-3 mb-5" style="width:100vw;min-height: 20px;background-color: #d0ebf2;"></div>
    
    <HomeMenus style="height: 100%;width: 100%;"></HomeMenus>

    <LoginDialog></LoginDialog>
  </div>
</template>

<script>
// import axios from "axios";
// import liff from "@line/liff";
import StandardHeader from "@/components/HeaderView/StandardHeader.vue";
import MemberShipCardView from "@/components/MemberShipCard/index.vue";
import HomeMenus from "@/components/HomeMenus/index.vue";
import LoginDialog from "@/components/Dialog/LoginDialog.vue";

import {
} from "@/store/action-types";
import { mapActions } from "vuex";
export default {
  name: "HomeViewTes",
  components: {
    StandardHeader,
    MemberShipCardView,
    HomeMenus,
    LoginDialog,
  },
  data() {
    return {
    };
  },
  watch: {
  },
  computed: {
  },
  async created() {
    // await Promise.all([
    //   await this.loadingStart(),
    //   await liff
    //     .init({
    //       liffId: "1657666852-pawRKym6",
    //       //developers "1656219888-Vpo1kp5e"
    //       //demo "1657666850-gaPDWLar",
    //       //stg "1657666851-9MmYDL3w",
    //       //product 1657666852-pawRKym6
    //       withLoginOnExternalBrowser: true,
    //     })
    //     .then(async () => {
    //       this.idToken = liff.getIDToken();
    //       this.LOG = this.idToken;
    //       this.getIsPutUserIdDB();
    //     }),
    // ]);
    //開発用------------------------------------------
    // console.log(liff), this.getIsPutUserIdDB();
    //------------------------------------------------
  },
  methods: {
  ...mapActions({
  }),
  }
};
</script>

<style>
</style>