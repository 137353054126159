<template>
  <div class="boxMenu">
    <div class="boxMenuRow">
      <v-card class="boxMenuItem" @click="openNahaLibraryPage('https://www.city.naha.okinawa.jp/lib/index.html#sp_header_logo')">
        <img src="@/assets/hp-new.png" alt="" />
        <v-card-text class="ma-0 pa-0" style="text-align: center; font-size:1rem">那覇市立図書館</v-card-text>
      </v-card>
      <v-card class="boxMenuItem" @click="openNahaLibraryPage('https://ilisod001.apsel.jp/naha-library/')">
        <img src="@/assets/search-new.png" alt="" />
        <v-card-text class="ma-0 pa-0" style="text-align: center; font-size:1rem">蔵書検索・予約</v-card-text>
      </v-card>
    </div>
    <div class="boxMenuRow">
      <v-card class="boxMenuItem"
        @click="openNahaLibraryPage('https://www.city.naha.okinawa.jp/lib/allNewsList.html#sp_header_logo')">
        <img src="@/assets/news-new.png" alt="" />
        <v-card-text class="ma-0 pa-0" style="text-align: center; font-size:1rem">新着情報</v-card-text>
      </v-card>

      <v-card class="boxMenuItem" @click="openNahaLibraryPage('https://ilisod001.apsel.jp/naha-library/sp/calendar')">
        <img src="@/assets/calendar-new.png" alt="" />
        <v-card-text class="ma-0 pa-0" style="text-align: center; font-size:1rem">閉館カレンダー</v-card-text>
      </v-card>
    </div>
  </div>
</template>
  
<script>
import liff from "@line/liff";
export default {
  name: "HomeMenus",
  data() {
    return {
    };
  },
  methods: {
    openNahaLibraryPage(url) {
      liff.openWindow({
        url: url
      });
    },
  }
};
</script>
<style>
.boxMenu {
  padding: 0vh 1.5vh;
}
.boxMenuRow {
  display: flex;
  justify-content: space-between;
}
.boxMenuRow:first-child {
  margin-bottom: min(1.6vh, 16px);
}
.boxMenuRow .boxMenuItem {
  height: 18vh;
  max-height: 153px;
  border-radius: 10px;
  color: #000;
  background: rgb(208, 235, 242);
  /* border: solid 1px rgba(0, 0, 0, 0.2); */
  padding: 16px 16px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 48.2%;
}
.boxMenuRow .boxMenuItem img {
  height: 100%;
}
.boxMenuRow .boxMenuItem:first-child {
  margin-right: 2%;
}
.boxMenuRow .boxMenuItem h3 {
  font-size: 1.1rem;
  margin: 1px 0 0;
}
</style>