import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '../views/LoginView.vue'
import HomeView from '../views/HomeView.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView
  },
  {
    path: '/login',
    name: 'LoginView',
    component: LoginView
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
import { confirmSignIn } from '@/mixins/index.js'
router.beforeEach(async (to, from, next) => {
  const isSignIn = await confirmSignIn()
  console.log('isSignIn', isSignIn)
  if (to.path === '/login') {
    next();
  } else if (isSignIn) {
    next();
  } else {
    next('/login');
  }
});

export default router
