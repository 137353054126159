<template>
    <header style="background-color: rgb(208, 235, 242);">
      <div style="min-height: 62px; display: flex;">
        <v-spacer />
        <div style="min-width: 52.2px"></div>
        <v-card-title style="font-weight: bold;">那覇市立図書館</v-card-title>
        <img style="max-height: 15vh; min-height: 5vh; height: 60px;" src="@/assets/headerIcon.png" alt="那覇市立図書館のアイコン" />
        <v-spacer />
      </div>
    </header>
  </template>
  
  <script>
  export default {
    name: "StandardHeader",
    props:[],
    data() {
      return {
      };
    },
    methods:{
    }
  };
  </script>