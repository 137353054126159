<template>
  <div class="barcodeArea">
    <div class="barcodeInner">
      <div class="barcodeHeader">利用者番号：{{ libraryUserId }}</div>
      <img src="https://placehold.jp/332x222.png" id="barcode" alt="" class="mt-6 mb-6"/>
    </div>
  </div>
</template>
  
<script>
import * as JsBarcode from "jsbarcode";
import { mapState } from "vuex";
export default {
  name: "MemberShipCard",
  data() {
    return {
    };
  },
  computed:{
    ...mapState({
      user: (state) => state.user,
    }),
    libraryUserId(){
      return Number(this.user.user_code)
    }
  },
  methods: {
    createBarcode() {
      JsBarcode("#barcode", this.libraryUserId, {
        format: "codabar",
        lineColor: "#000",
        width: 2,
        height: 40,
        displayValue: true,
      });
      this.loadingStop();
    },
  },
  mounted(){
    this.createBarcode();
  }
};
</script>
<style scoped>
.barcodeArea {
  width: 100%;
  padding: 0 16px 0px;
}
.barcodeArea img {
  margin-top: 1vh;
  width: 100%;
}
.barcodeInner {
  padding-bottom: 16px;
  border: solid 1px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 6px 9px -5px #777777;
  filter: brightness(1.2);
}
.barcodeInner .barcodeHeader {
  background: #0000dd;
  height: 12vh;
  max-height: 60px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.barcodeInner #barcode {
  margin: min(2vh, 25px) 0 min(1vh, 10px);
}
.barcodeInner p {
  font-size: 0.6rem;
  font-weight: bold;
  text-align: center;
}
</style>